import React, { ReactElement, Suspense } from "react"
import { useRoutes } from "react-router-dom"

const WagmiLayout = React.lazy(() => import("../components/layout/WagmiLayout"))
const OverviewPage = React.lazy(() => import("./overview"))
const AccountPage = React.lazy(() => import("./account"))
const CreateNFTPage = React.lazy(() => import("./create_nft"))
const CreateCollectionPage = React.lazy(() => import("./create_collection"))
const CollectionPage = React.lazy(() => import("./collection"))
const AssetsPage = React.lazy(() => import("./assets"))
const NotFoundPage = React.lazy(() => import("./404"))
const LoginPage = React.lazy(() => import("./login"))
const HowToUsePage = React.lazy(() => import("./how_to_use"))
const HowToBuyPage = React.lazy(() => import("./buy_eth"))
const MetamaskPage = React.lazy(() => import("./metamask"))
const WithdrawalPage = React.lazy(() => import("./withdrawal"))

export function App(): ReactElement | null {
  return useRoutes([
    {
      element: (
        <Suspense>
          <WagmiLayout />
        </Suspense>
      ),
      children: [
        {
          path: "/account",
          element: (
            <Suspense>
              <AccountPage />
            </Suspense>
          ),
        },
        {
          path: "/collection/:collectionId",
          element: (
            <Suspense>
              <CollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/nft/:nftId/:tokenId",
          element: (
            <Suspense>
              <AssetsPage />
            </Suspense>
          ),
        },
        {
          path: "/create_nft",
          element: (
            <Suspense>
              <CreateNFTPage />
            </Suspense>
          ),
        },
        {
          path: "/create_collection",
          element: (
            <Suspense>
              <CreateCollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/collection/:collectionId",
          element: (
            <Suspense>
              <CollectionPage />
            </Suspense>
          ),
        },
        {
          path: "/nft",
          element: (
            <Suspense>
              <AssetsPage />
            </Suspense>
          ),
        },
        {
          path: "/nft/:nftId/:tokenId",
          element: (
            <Suspense>
              <AssetsPage />
            </Suspense>
          ),
        },
        {
          path: "/buy_eth",
          element: (
            <Suspense>
              <HowToBuyPage />
            </Suspense>
          ),
        },
        {
          path: "/how_to_use",
          element: (
            <Suspense>
              <HowToUsePage />
            </Suspense>
          ),
        },
        {
          path: "/metamask",
          element: (
            <Suspense>
              <MetamaskPage />
            </Suspense>
          ),
        },
        {
          path: "/withdrawal",
          element: (
            <Suspense>
              <WithdrawalPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <Suspense>
          <OverviewPage />
        </Suspense>
      ),
    },
    {
      path: "/login",
      element: (
        <Suspense>
          <LoginPage />
        </Suspense>
      ),
    },
    {
      path: "/404",
      element: (
        <Suspense>
          <NotFoundPage />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense>
          <NotFoundPage />
        </Suspense>
      ),
    },
  ])
}
