import { TranslateType } from "@/core/type/Translate"

export const enKeyCode: TranslateType = {
  str_account: "Account",
  str_amount: "Amount",
  str_amount_placeholder: "Please enter amount",
  str_balance_money: "Balance: {{money}} DIV",
  str_banner_image: "Banner Image",
  str_banner_image_hint:
    "This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices.",
  str_blockchain: "Blockchain",
  str_blockchain_placeholder: "Please select blockchain",
  str_burn: "Burn",
  str_burn_btn: "I understand and Burn this NFT",
  str_burn_content:
    "Burning a non-fungible token means destroying it. Burned NFTs are\n sent to a verifiable un-spendable address, ultimately eliminating\n your NFT from the blockchain.",
  str_burn_hint: " * The action can't restore after submit",
  str_burn_success: "Burn Application Successfully",
  str_burn_success_message:
    "When Transaction finish, the NFT will be remove from your own NFT",
  str_buy_eth: "How to buy ETH",
  str_can_not_transfer_nft_yourself: "You can't transfer your NFT to yourself",
  str_category: "Category",
  str_category_placeholder: "Please select category",
  str_collection: "Collection",
  str_collection_description: "Collection Description",
  str_collection_description_placeholder:
    "Provide a detailed description of your collection",
  str_collection_empty_message: "Create your first Collection",
  str_collection_name: "Collection Name",
  str_collection_name_placeholder: "Please enter collection name",
  str_connect_error_content:
    "Please check your metamask wallet to authorize 'www.mint-nft.jp' site.",
  str_connect_error_title: "Connecting Metamask Wallet Error",
  str_connect_wallet: "Connect Wallet",
  str_contract_address: "Contract address",
  str_copy_success: "Copied Successfully",
  str_create_collection: "Create Collection",
  str_create_collection_btn: "Create Collection",
  str_create_collection_step1_message: "Uploading...",
  str_create_collection_step2_message: "Creating...",
  str_create_collection_success: "Create Collection Successfully",
  str_create_collection_success_message:
    "The collection is created and you can create NFT directly",
  str_create_nft: "Create NFT",
  str_date: "Date",
  str_description: "Description",
  str_download_diver_wallet: "Download DIVER Wallet",
  str_error_message_name_limit: "Please enter less than 1,000 characters",
  str_event: "Event",
  str_external_link: "External Link",
  str_failed: "Failed",
  str_feature_image: "Feature Image",
  str_feature_image_hint:
    "This image will be used for featuring your collection on the homepage, category pages.",
  str_file_size_exceed_10MB: "File size exceeds 10MB",
  str_file_upload_failed: "Failed to upload file",
  str_get_account_error:
    "Can't get our NFT / collection, please try again or contact support.",
  str_get_collection_failed: "Failed to get collections",
  str_get_upload_link_failed: "Failed to Retrieve S3 Upload Link",
  str_goto_account: "Back to Account",
  str_goto_create_nft: "Go to create NFT",
  str_how_to_use: "How to Use?",
  str_how_to_use_to_know: "User Manual",
  str_how_to_use_to_know_with_doc: "User Manual",
  str_how_to_use_to_know_with_video: "User Manual with video",
  str_information: "Information",
  str_install: "Install",
  str_item_activity: "Item Activity",
  str_link: "Link",
  str_logo_image: "Logo Image",
  str_logo_image_hint: "This image will also be used for navigation.",
  str_metamask_is: "METAMASKを",
  str_metamask_use: "How to Use Metamask?",
  str_mint_nft_btn: "Mint NFT",
  str_mint_success: "Created Successfully",
  str_mint_success_message:
    "When Transaction finish, the NFT will be display in account",
  str_missing_form_data: "Missing Form Data",
  str_my_collection: "My Collection",
  str_network_error_confirmBtn: "I understand",
  str_network_error_message:
    "The network is incorrect, please switch to the right network",
  str_network_error_title: "Error Network!",
  str_nft_burn: "NFT Burn",
  str_nft_collection: "Collection",
  str_nft_collection_placeholder: "Please select Collection",
  str_nft_cover: "NFT Cover",
  str_nft_cover_hint:
    "File types supported: JPG, PNG, GIF, SVG. Max size: 10 MB",
  str_nft_description: "NFT Description",
  str_nft_description_placeholder:
    "Provide a detailed description of your goods",
  str_nft_detail: "NFT Detail",
  str_nft_empty_message: "Collect your first NFT",
  str_nft_from: "From",
  str_nft_name: "NFT Name",
  str_nft_name_placeholder: "Please enter NFT name",
  str_nft_photo: "NFT Image, Video, Audio",
  str_nft_photo_hint:
    "File types supported: JPG, PNG, GIF, SVG, MP4, WAV, MP3. Max size: 10 MB",
  str_nft_price: "Price",
  str_nft_price_placeholder: "The price range is 10 ~ 10000000000",
  str_nft_selling_enable: "Do you want to sell this NFT?",
  str_nft_supply: "Supply",
  str_nft_supply_placeholder: "The number of items that can be minted",
  str_nft_to: "To",
  str_nft_transfer: "NFT Transfer",
  str_nft_un_selling_enable: "Do you want to stop sell this NFT?",
  str_nft_un_selling_success: "Stop sell success",
  str_owner: "Owner",
  str_please_enter_a_valid_number: "Please enter a valid number",
  str_please_enter_ten_multiples: "Please enter a multiple of ten",
  str_please_enter_your_account: "Please enter your account",
  str_please_enter_your_password: "Please enter your password",
  str_please_fill_out_this_field: "Please fill out this field",
  str_please_select: "Please Select",
  str_price: "Price",
  str_privacy_policy: "Privacy Policy",
  str_required: "Required Fields",
  str_search_collection_nft_placeholder: "Search by NFT name",
  str_search_empty_message: "Search No Found",
  str_search_placeholder: "Search for NFT and Collection",
  str_sell: "Sell",
  str_sell_NFT: "Sell NFT",
  str_sell_NFT_apply_success: "The NFT is on the market",
  str_setting: "Setting",
  str_share: "Share",
  str_sign_out: "Sign Out",
  str_sort_name_asc: "NFT Name (Z -> A)",
  str_sort_name_desc: "NFT Name (A -> Z)",
  str_sort_newest: "Recently Created",
  str_sort_oldest: "Oldest",
  str_stop_sell_NFT: "Stop Sell NFT",
  str_stop_sell_NFT_apply_success: "The NFT no longer be sold",
  str_term_of_service: "Terms of Service",
  str_token_id: "Token ID",
  str_token_standard: "Token Standard",
  str_total_balance: "Total Balance",
  str_transfer: "Transfer",
  str_transfer_success: "Transfer Application Successfully",
  str_transfer_success_message:
    "When Transaction finish, the NFT will be display in account",
  str_upload_ipfs_failed: "Failed to Upload in IPFS",
  str_wallet_address: "Wallet Address",
  str_wallet_address_placeholder: "Please enter wallet address",
  str_welcome: "Welcome",
  str_withdraw_funds: "Withdraw Funds",
  str_withdraw_funds_exceed_balance: "Withdrawal amount exceeds the balance.",
  str_withdraw_funds_success: "Withdraw Funds Successfully",
  str_withdrawal: "Withdrawal",
  str_only_image_file:"Only image files (JPG, PNG, GIF, SVG) can be uploaded.",
  str_mint_fee: "※Each time you Mint an NFT, MINT NFT will charge you 2,000,000 DIV as the Minting fee."
}
