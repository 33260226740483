import { Theme, ThemeProvider } from "@mui/material"
import React, { PropsWithChildren, useEffect } from "react"
import AlertContainer from "@/core/store/AlertContainer"
import "../../styles/layout.css"
import "../../plugins/i18next"
import { bandaiTheme } from "@/core/theme/bandaiTheme"
import { AlertMessage } from "../common/AlertMessage"
import { AlertModal } from "../common/AlertModal"
import { useLocation, useSearchParams } from "react-router-dom"

export type LayoutProps = {
  theme?: Theme
}

const Layout: React.FunctionComponent<PropsWithChildren<LayoutProps>> = ({
  children,
  theme = bandaiTheme,
}) => {
  const [searchParams] = useSearchParams()
  const isMobileDiverWallet = searchParams.get("is_diver_wallet") === "true"
  const location = useLocation()

  useEffect(() => {
    if (isMobileDiverWallet) {
      window.ethereum.isDiverWallet = true
      localStorage.setItem("is_diver_wallet", "true")
    }
  }, [])

  useEffect(() => scrollTo(0, 0), [location.pathname])

  return (
    <ThemeProvider theme={theme}>
      <AlertContainer.Provider>
        <main>{children}</main>
        <AlertMessage />
        <AlertModal />
      </AlertContainer.Provider>
    </ThemeProvider>
  )
}

export default Layout
