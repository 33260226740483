import { createTheme } from "@mui/material"

export const bandaiTheme = createTheme({
  palette: {
    primary: {
      light: "#a5e6ec",
      main: "#26c2d4",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#4383E4",
      dark: "#0F57C2",
    },
    warning: {
      main: "#ED6C02",
      dark: "#C75F00",
      contrastText: "#feeee3",
    },
    error: {
      main: "#FF675E",
      dark: "#BF2C21",
    },
    info: { main: "#8A77ED", dark: "#604BBE" },
    text: {
      primary: "#000000",
      secondary: "#757575",
      disabled: "rgba(158, 158, 158, 0.4)",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
      footerBg: "#DCDDDF",
      buttonBg: "#26C2D4",
      formControlBg: "#f5f8fa",
      popupBg: "",
      popupDialogBg: "",
      headerShadow: "3px 3px 5px rgba(38, 195, 212, 0.5)",
    },
    action: {
      normal: "#dcdddf",
      active: "rgba(219, 232, 246, 0.4)",
      hover: "rgba(59, 105, 174, 0.08)",
      selected: "rgba(47, 91, 158, 0.08)",
      disabled: "rgba(0, 0, 0, 0.3)",
    },
    grey: {
      100: "rgba(242, 244, 245, 0.08)",
      200: "rgba(242, 244, 245, 0.12)",
      300: "rgba(231, 234, 240, 0.12)",
      400: "#4D4D4D",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
})
