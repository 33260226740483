import { TranslateType } from "@/core/type/Translate"

export const jaKeyCode: TranslateType = {
  str_account: "アカウント",
  str_amount: "金額",
  str_amount_placeholder: "金額を入力してください",
  str_balance_money: "残高: {{money}} DIV",
  str_banner_image: "バナー画像",
  str_banner_image_hint:
    "この画像は、コレクションページの上部に表示されます。デバイスによって寸法が変わるため、このバナー画像にテキストを入れすぎないようにしてください。1400×350px推奨",
  str_blockchain: "ブロックチェーン",
  str_blockchain_placeholder: "ブロックチェーンを選択",
  str_burn: "焼却",
  str_burn_btn: "了解してこのNFTを焼却する",
  str_burn_content:
    "非代替トークンを焼却すると、それが破壊されます。焼却されたNFTは検証可能な支出不可アドレスに送信され、結果的にはブロックチェーンからNFTが削除されます。",
  str_burn_hint: " * このアクションは送信後に元に戻すことはできません",
  str_burn_success: "焼却申請が成功しました",
  str_burn_success_message:
    "トランザクションが完了すると、NFTはあなたのNFTから削除されます",
  str_buy_eth: "ETHの買い方",
  str_can_not_transfer_nft_yourself: "自分にNFTを転送することはできません",
  str_category: "コレクションカテゴリ",
  str_category_placeholder: "カテゴリを選択",
  str_collection: "コレクション",
  str_collection_description: "コレクション説明",
  str_collection_description_placeholder:
    "コレクションに必要な説明文を1000文字以内で記入してください。",
  str_collection_empty_message: "最初のコレクションを作成してください",
  str_collection_name: "お名前",
  str_collection_name_placeholder: "コレクションの名称を入力してください",
  str_connect_error_content:
    "'www.mint-nft.jp' サイトを承認するためにDIVERウォレットを確認してください。",
  str_connect_error_title: "DIVERウォレット接続エラー",
  str_connect_wallet: "ウォレットを接続する",
  str_contract_address: "コントラクトアドレス",
  str_copy_success: "コピー",
  str_create_collection: "コレクションを作る",
  str_create_collection_btn: "コレクションを作成する",
  str_create_collection_step1_message: "アップロード中...",
  str_create_collection_step2_message: "作成中...",
  str_create_collection_success: "コレクションの作成に成功しました",
  str_create_collection_success_message:
    "コレクションが作成され、直接NFTを作成できます",
  str_create_nft: "NFTを作る",
  str_date: "日時",
  str_description: "詳細説明",
  str_download_diver_wallet: "DIVER Walletをダウンロード",
  str_error_message_name_limit: "1,000文字以下で入力してください",
  str_event: "項目",
  str_external_link: "外部リンク",
  str_failed: "失敗した",
  str_feature_image: "フィーチャー画像",
  str_feature_image_hint:
    "この画像は、ホームページ、カテゴリページでコレクションを紹介するために使用されます。600×400px推奨",
  str_file_size_exceed_10MB: "ファイルサイズが10MBを超えています",
  str_file_upload_failed: "ファイルのアップロードに失敗しました",
  str_get_account_error:
    "NFT / コレクションを取得できません。もう一度試すか、サポートにお問い合わせください。",
  str_get_collection_failed: "コレクションの取得に失敗しました",
  str_get_upload_link_failed: "S3アップロードリンクの取得に失敗しました",
  str_goto_account: "アカウントに戻る",
  str_goto_create_nft: "NFTを作成するにはこちら",
  str_how_to_use: "使い方",
  str_how_to_use_to_know: "使い方を見る",
  str_how_to_use_to_know_with_doc: "使い方記事を読む",
  str_how_to_use_to_know_with_video: "使い方動画を見る",
  str_information: "情報",
  str_install: "インストール",
  str_item_activity: "履歴",
  str_link: "リンク",
  str_logo_image: "ロゴイメージ",
  str_logo_image_hint: "コレクションのアイコンとなる画像です。350×350以上推奨",
  str_metamask_is: "METAMASKを",
  str_metamask_use: "MetaMaskの使い方",
  str_mint_nft_btn: "MINT NFT",
  str_mint_success: "成功に作成されました",
  str_mint_success_message:
    "トランザクションが完了すると、NFTがアカウントに表示されます",
  str_missing_form_data: "フォームデータが欠落しています",
  str_my_collection: "マイコレクション",
  str_network_error_confirmBtn: "了解しました",
  str_network_error_message:
    "ネットワークが正しくありません。正しいネットワークに切り替えてください",
  str_network_error_title: "ネットワークエラー",
  str_nft_burn: "NFTを焼却",
  str_nft_collection: "コレクション",
  str_nft_collection_placeholder: "コレクションを選択",
  str_nft_cover: "NFT カバー",
  str_nft_cover_hint:
    "サポートされているファイルタイプ: JPG、PNG、GIF、SVG。最大サイズ: 10 MB",
  str_nft_description: "NFT説明",
  str_nft_description_placeholder: "説明文を1000文字以内で記入してください。",
  str_nft_detail: "詳細",
  str_nft_empty_message: "最初のNFTを集めましょう",
  str_nft_from: "From",
  str_nft_name: "NFT名称",
  str_nft_name_placeholder: "NFTの名",
  str_nft_photo: "NFT 画像、ビデオ、音声",
  str_nft_photo_hint:
    "サポートされているファイルタイプ: JPG、PNG、GIF、SVG、MP4、WAV、MP3。最大サイズ: 10 MB",
  str_nft_price: "販売価格",
  str_nft_price_placeholder: "価格は 10 〜 10000000000 です。",
  str_nft_selling_enable: "このNFTを販売しますか?",
  str_nft_supply: "NFTの数",
  str_nft_supply_placeholder: "鋳造できるのはNFTの数",
  str_nft_to: "To",
  str_nft_transfer: "NFTを転送",
  str_nft_un_selling_enable: "この商品の販売を中止しますか?",
  str_nft_un_selling_success: "販売停止の成功",
  str_owner: "オーナー",
  str_please_enter_a_valid_number: "有効な数値を入力してください",
  str_please_enter_ten_multiples: "10の倍数を入力してください",
  str_please_enter_your_account: "アカウントを入力してください",
  str_please_enter_your_password: "パスワードを入力してください",
  str_please_fill_out_this_field: "ブロックに記入してください",
  str_please_select: "選択してください",
  str_price: "プライス",
  str_privacy_policy: "プライバシーポリシー",
  str_required: "*印は入力必須項目です。",
  str_search_collection_nft_placeholder: "NFTの名称を探す",
  str_search_empty_message: "検索結果が見つかりません",
  str_search_placeholder: "コレクション、アイテムを探す",
  str_sell: "販売",
  str_sell_NFT: "NFTを販売",
  str_sell_NFT_apply_success: "商品の出品が成功しました",
  str_setting: "設定",
  str_share: "シェア",
  str_sign_out: "サインアウト",
  str_sort_name_asc: "NFT Name (Z -> A)",
  str_sort_name_desc: "NFT Name (A -> Z)",
  str_sort_newest: "最近作成",
  str_sort_oldest: "最古",
  str_stop_sell_NFT: "販売停止",
  str_stop_sell_NFT_apply_success: "商品の取り下げが成功しました",
  str_term_of_service: "利用規約",
  str_token_id: "トークンID",
  str_token_standard: "トークン規格",
  str_total_balance: "合計残高",
  str_transfer: "転送",
  str_transfer_success: "転送申請が成功しました",
  str_transfer_success_message:
    "トランザクションが完了すると、NFTがアカウントに表示されます",
  str_upload_ipfs_failed: "IPFSへのアップロードに失敗しました",
  str_wallet_address: "ブロックチェーンアドレス",
  str_wallet_address_placeholder: "アドレスを入力してください",
  str_welcome: "ようこそ",
  str_withdraw_funds: "引き出し",
  str_withdraw_funds_exceed_balance: "引き出し額が残高を超えています",
  str_withdraw_funds_success: "引き出し成功",
  str_withdrawal: "お引出し",
  str_only_image_file: "画像ファイル（JPG、PNG、GIF、SVG）のみアップロードできます",
  str_mint_fee: "※NFTをミントするたびに、ミント料として 2,000,000 DIVを請求いたします。"
}
