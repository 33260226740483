import { Box, Dialog, useTheme } from "@mui/material"
import React, { ReactNode } from "react"
import { DialogTypeEnum } from "@/core/const/DialogTypeEnum"
import { PrimaryButton } from "./PrimaryButton"
import CloseIcon from "@mui/icons-material/Close"

export type CommonDialogProps = {
  children?: ReactNode
  type?: DialogTypeEnum
  open: boolean
  close?: () => void
  title?: string
  content?: string | JSX.Element
  buttonLeft?: {
    text: string
    action?: () => void
    loading?: boolean
  }
  buttonRight?: {
    text: string
    action?: ([key]?: any) => void
    loading?: boolean
  }
}

export const CommonDialog: React.FunctionComponent<CommonDialogProps> = ({
  open,
  close = () => undefined,
  type,
  title,
  content,
  buttonLeft,
  buttonRight,
  children,
}) => {
  const { palette } = useTheme()

  const iconDisplay = {
    [DialogTypeEnum.INFO]: "/images/icon/question.svg",
    [DialogTypeEnum.SUCCESS]: "/images/icon/success.svg",
    [DialogTypeEnum.WARNING]: "/images/icon/warning.svg",
    [DialogTypeEnum.ERROR]: "/images/icon/fail.svg",
    [DialogTypeEnum.DELETE]: "/images/icon/warning.svg",
  }
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 4,
          backgroundColor: palette.background.popupDialogBg,
        },
        "& .MuiBackdrop-root": {
          backgroundColor: palette.background.popupBg,
        },
      }}
    >
      <Box
        sx={{
          color: palette.text.secondary,
          "&:hover": { color: palette.error.main },
        }}
        className="absolute right-3 top-2 cursor-pointer"
        onClick={() => close()}
      >
        <CloseIcon />
      </Box>
      <div className="w-auto min-w-[432px] px-8 py-12">
        {children || (
          <>
            {type && type !== DialogTypeEnum.FORM && (
              <img
                className="mx-auto mt-2"
                src={iconDisplay[type]}
                alt="icon"
              />
            )}
            <div className="text-h4 mb-4 mt-4 text-center">{title}</div>
            <div className="text-caption1 text-center font-light">
              {content}
            </div>
            <div className="mt-6 flex justify-center px-4">
              {buttonLeft && (
                <PrimaryButton
                  color="primary"
                  variant="text"
                  onClick={() => {
                    buttonLeft.action ? buttonLeft.action() : close()
                  }}
                >
                  {buttonLeft.text || "Cancel"}
                </PrimaryButton>
              )}
              {buttonRight && (
                <PrimaryButton
                  color={type === DialogTypeEnum.DELETE ? "error" : "primary"}
                  variant="contained"
                  onClick={() => {
                    buttonRight.action ? buttonRight.action() : close()
                  }}
                >
                  {buttonRight.text || "Confirm"}
                </PrimaryButton>
              )}
            </div>
          </>
        )}
      </div>
    </Dialog>
  )
}
