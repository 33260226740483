import { Alert, Slide, Snackbar } from "@mui/material"
import { SlideProps } from "@mui/material/Slide/Slide"
import React from "react"
import AlertContainer from "../../core/store/AlertContainer"

type AlertMessageProps = {}

export const AlertMessage: React.FunctionComponent<AlertMessageProps> = () => {
  const {
    state: { display, severity, message },
  } = AlertContainer.useContainer()

  const TransitionLeft = (props: SlideProps) => {
    return <Slide {...props} direction="down" />
  }

  return (
    <>
      {display && (
        <Snackbar
          open={display}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={TransitionLeft}
        >
          <Alert severity={severity} elevation={6}>
            <span className="whitespace-pre-line">{message}</span>
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
