import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import { useTheme } from "@mui/material"
import React from "react"

const isEqual = (
  prevProps: LoadingButtonProps,
  nextProps: LoadingButtonProps
) => {
  return !(
    prevProps.variant !== nextProps.variant ||
    prevProps.children !== nextProps.children ||
    prevProps.disabled !== nextProps.disabled ||
    prevProps.classes !== nextProps.classes ||
    prevProps.className !== nextProps.className ||
    prevProps.loading !== nextProps.loading
  )
}

export const PrimaryButton: React.FunctionComponent<LoadingButtonProps> =
  React.memo((props: LoadingButtonProps) => {
    const { palette } = useTheme()
    return (
      <LoadingButton
        {...props}
        sx={{
          fontFamily: "inherit",
          "&.MuiLoadingButton-loading": {
            background: palette.action.active,
          },
          "& .MuiLoadingButton-loadingIndicator": {
            color: "white",
          },
          color: props.loading
            ? "transparent"
            : props.variant === "contained" && !props.disabled
            ? "white"
            : "",
          fontWeight: "bold",
          fontSize: "16px",
          lineHeight: "normal",
          height: "41px",
          borderRadius: "5px",
          boxShadow: "none",
          width: "fit-content",
          maxWidth: "378px",
          padding: "0 12px",
          background: props.disabled ? palette.action.disabled : "",
          "&:not(.MuiButton-outlined)": {
            background: props.disabled
              ? palette.action.disabled
              : palette.background.buttonBg,
          },
          ...(props.sx || {}),
        }}
      />
    )
  }, isEqual)

PrimaryButton.displayName = "PrimaryButton"
