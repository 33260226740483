import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { jaKeyCode } from "@/core/const/jaTranslate"
import { enKeyCode } from "@/core/const/enTranslate"

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ja",
    lng:
      (typeof window !== "undefined" && localStorage.getItem("lang")) || "ja",
    debug: false,
    resources: {
      ja: {
        translation: jaKeyCode,
      },
      en: {
        translation: enKeyCode,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch()

export default i18next
