import { AlertColor } from "@mui/material/Alert/Alert"
import { PropsWithChildren, useState } from "react"
import { createContainer } from "unstated-next"
import { CommonDialogProps } from "../../components/common/CommonDialog"

type MessageState = {
  severity?: AlertColor
  message?: string
  display?: boolean
}

function useAlert() {
  const [state, setState] = useState<MessageState>({
    severity: "error",
    message: "",
    display: false,
  })

  const [modalState, setModalState] = useState<CommonDialogProps>({
    open: false,
  })

  const openModal = (
    props: PropsWithChildren<Omit<CommonDialogProps, "open">>
  ) => setModalState({ open: true, close: closeModal, ...props })

  const closeModal = () => setModalState({ open: false })

  const changeModalState = (props: CommonDialogProps) =>
    setModalState({ ...props })

  const pushAlert = (newState: MessageState) => {
    setState({ ...state, ...newState, display: true })

    setTimeout(() => {
      setState(preState => ({ ...preState, display: false }))
    }, 2000)
  }

  return {
    state,
    pushAlert,
    modalState,
    openModal,
    closeModal,
    changeModalState,
  }
}

const AlertContainer = createContainer(useAlert)

export default AlertContainer
